import React, {useEffect} from 'react'
import Routes from "./Routes"
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {changeField} from "./redux/actions";
import Preloader from "./components/Preloader";

function App() {

  const history = useHistory()
  const windowToken = window.localStorage.getItem('token')
  const dispatch = useDispatch()

  useEffect(() => {
    if (windowToken) {
      dispatch(
          changeField({
            field: 'token',
            value: windowToken
          })
      )
    } else {
      history.push('/auth')
    }
  }, [windowToken])

  return (
      <>
        <Preloader />
        <Routes />
      </>
  );
}

export default App;

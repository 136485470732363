import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {changeField, getTransports} from "../redux/actions";
import TransportTableStyled from "../styles/TransportTableStyled";
import {useHistory} from "react-router-dom";

const TransportsTable = () => {

    const dispatch = useDispatch()
    const token = useSelector(state => state.token)
    const [transports, setTransports] = useState([])
    const history = useHistory()
    const filter = useSelector(state => state.transportFilter)

    const onChange = (e) => {
        dispatch(
            changeField({
                field: 'transportFilter',
                value: {
                    ...filter,
                    [e.target.name]: e.target.value
                }
            })
        )
    }

    useEffect(() => {
        if (token) {

            let filterValue = ''

            if (filter.licensePlate) {
                filterValue += `&license_plate=${filter.licensePlate}`
            }

            dispatch(
                getTransports({
                    token,
                    filter: filterValue,
                    onSuccess: (res) => {
                        setTransports(res)
                    }
                })
            )
        }
    }, [token, filter])

    return (
        <TransportTableStyled width="100%">

            <div className="tableHeaderRow" style={{display: 'flex'}}>
                <div className="tableHeaderLabel">Kenteken</div>
            </div>

            <div className="tableHeaderRow">
                <div className="tableHeaderFilter">
                    <input onChange={onChange} value={filter.licensePlate} name="licensePlate" type="text"/>
                </div>
            </div>

            <div className="tableBody">
                {
                    transports ?
                        transports.map((item) => {
                            return (
                                <div key={item[5]} className="tableRow">
                                    <div onClick={() => {
                                        history.push(`/new-transportation/${item[5]}`)
                                    }}>{item[3]}</div>
                                </div>
                            )
                        })
                        :
                        ''

                }
            </div>

        </TransportTableStyled>
    )
}

export default TransportsTable

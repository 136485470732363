import React from 'react'
import PreloaderWrapper from "../styles/PreloaderWrapper";
import {useSelector} from "react-redux";

const Preloader = () => {

    const isPreloaderActive = useSelector(state => state.isPreloaderActive)

    if (isPreloaderActive) {

        return (
            <PreloaderWrapper>
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </PreloaderWrapper>
        )

    } else {
        return false
    }
}

export default Preloader

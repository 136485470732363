import React, {useState} from 'react'
import {Avatar, Box, Button, Container, CssBaseline, Grid, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useDispatch} from "react-redux";
import {changeField, login} from "../redux/actions";
import {useHistory} from "react-router-dom";

const AuthPage = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const [stage, setStage] = useState("login")

    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
        googleAuth: ''
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(
            login({
                email: credentials.email,
                password: credentials.password,
                googleAuth: credentials.googleAuth,
                onSuccess: (token) => {
                    console.log("Success! Token: " + token)
                    window.localStorage.setItem('token', token)
                    dispatch(
                        changeField({
                            field: 'token',
                            value: token
                        })
                    )
                    history.push('/')
                },
                onError: () => {
                    alert("Wrong credentials")
                    setStage("login")
                }
            })
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1}}>
                    <LockOutlinedIcon/>
                </Avatar>

                {
                    stage == 'login'?
                        <>
                            <Typography component="h1" variant="h5">
                                Log in
                            </Typography>
                            <Box component="form" noValidate sx={{mt: 1}}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="E-mailadres"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={(e) => {
                                        setCredentials({
                                            ...credentials,
                                            email: e.target.value
                                        })
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Wachtwoord"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={(e) => {
                                        setCredentials({
                                            ...credentials,
                                            password: e.target.value
                                        })
                                    }}
                                />

                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                    onClick={() => {
                                        setStage("googleAuth")
                                    }}
                                >
                                    Log in
                                </Button>
                            </Box>
                        </>
                        :
                        ''
                }

                {
                    stage == 'googleAuth' ?
                        <>
                            <Typography component="h1" variant="h5">
                                Google Authentication code
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="googleAuth"
                                    label="Google Auth code"
                                    name="googleAuth"
                                    autoFocus
                                    onChange={(e) => {
                                        setCredentials({
                                            ...credentials,
                                            googleAuth: e.target.value
                                        })
                                    }}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                >
                                    Confirm
                                </Button>
                            </Box>
                        </>
                        :
                        ''
                }

            </Box>
        </Container>
    )
}

export default AuthPage

import config from "../config";
import axios from "axios";

export const changeField = ({field, value}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_FIELD',
            payload: {
                field,
                value
            }
        })
    }
}

export const login = ({email, password, googleAuth, onSuccess, onError}) => {
    return (dispatch) => {

        dispatch(
            changeField({
                field: 'isPreloaderActive',
                value: true
            })
        )

        axios({
            method: 'post',
            url: config.baseUrl + '/public/employee/login',
            data: {
                username: email,
                password,
                "2fa_code": googleAuth
            }
        }).then((res) => {
            console.log(res)
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            onSuccess(res.data.token)
        }).catch((err) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            onError()
            console.log(err)
        })
    }
}

export const getTransportations = ({token, onSuccess, filter = false}) => {
    return (dispatch) => {

        dispatch(
            changeField({
                field: 'isPreloaderActive',
                value: true
            })
        )

        axios({
            method: 'get',
            url: config.baseUrl + (filter ? `/employee/transportations${filter}`: '/employee/transportations'),
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            onSuccess(res.data.data)
        }).catch((err) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            console.log(err)
        })
    }
}

export const getTransportationInfo = ({token, id, onSuccess}) => {
    return (dispatch) => {

        dispatch(
            changeField({
                field: 'isPreloaderActive',
                value: true
            })
        )

        axios({
            method: 'get',
            url: config.baseUrl + `/employee/transport-order/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            onSuccess(res.data)
        }).catch((err) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            console.log(err)
        })
    }
}

export const getTransports = ({token, onSuccess, filter = false}) => {
    return (dispatch) => {

        dispatch(
            changeField({
                field: 'isPreloaderActive',
                value: true
            })
        )

        axios({
            method: 'get',
            url: config.baseUrl + (filter ? `/employee/transports?limit=200&offset=0${filter}` : '/employee/transports?limit=200&offset=0'),
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            onSuccess(res.data.data)
        }).catch((err) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            console.log(err)
        })
    }
}

export const getNewTransportation = ({token, carID, onSuccess}) => {
    return (dispatch) => {

        dispatch(
            changeField({
                field: 'isPreloaderActive',
                value: true
            })
        )

        axios({
            method: 'get',
            url: config.baseUrl + `/employee/transports/${carID}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((res) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            onSuccess(res.data)
        }).catch((err) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            console.log(err)
        })
    }
}

export const createNewTransportation = ({token, vanId, transportation, onSuccess}) => {
    return (dispatch) => {

        dispatch(
            changeField({
                field: 'isPreloaderActive',
                value: true
            })
        )

        axios({
            method: 'post',
            url: config.baseUrl + `/employee/transports/${vanId}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            data: {
                subsidiary: 9,
                pickup_location_id: transportation.pickup_location_id,
                return_location_id: transportation.return_location_id,
                driver_id: transportation.driver_id,
                transport_reason: transportation.transport_reason,
                transport_method: transportation.transport_method,
                from_date: transportation.from_date,
                to_date: transportation.to_date,
                need_action: transportation.need_action
            }
        }).then((res) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            onSuccess(res.data)
        }).catch((err) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            console.log(err)
        })
    }
}

export const changeTransportationStatus = ({token, transportationId, status, onSuccess}) => {
    return (dispatch) => {

        dispatch(
            changeField({
                field: 'isPreloaderActive',
                value: true
            })
        )

        axios({
            method: 'post',
            url: config.baseUrl + `/employee/transport-order/${transportationId}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            data: {
                "status": status
            }
        }).then((res) => {

            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )

            onSuccess(res.data)
        }).catch((err) => {

            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )

            console.log(err)
        })
    }
}

export const changeReservationTime = ({token, id, startDate, endDate, return_location_id, onSuccess, onError}) => {
    return (dispatch) => {
        dispatch(
            changeField({
                field: 'isPreloaderActive',
                value: true
            })
        )

        axios({
            method: 'post',
            url: config.baseUrl + `/employee/transport-order/${id}/edit`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            data: {
                planned_start_date: startDate,
                planned_end_date: endDate,
                return_location_id
            }
        }).then((res) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            onSuccess(res.data)
        }).catch((err) => {
            dispatch(
                changeField({
                    field: 'isPreloaderActive',
                    value: false
                })
            )
            onError(err)
        })
    }
}

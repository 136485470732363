import styled from 'styled-components'

const TransportTableStyled = styled.div`
  padding: 8px;
  
  div {
    margin: 2px;
  }

  .tableHeaderRow {
    display: flex;
    
    > div {
      width: ${props => props.width ? props.width : '50%'} ;
    }
  }
  
  .tableHeaderLabel {
    font-weight: bold;
    padding-top: 8px;
    margin-bottom: 8px;
    padding-left: 2px;
    padding-right: 2px;
  }
  
  .tableHeaderFilter {
    input, select {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      box-sizing: border-box;
      width: 100%;
      height: 40px;
    }
  }
  
  .tableRow {
    display: flex;
    > div {
      width: ${props => props.width ? props.width : '50%'};
      font-family: "Roboto", sans-serif;
      border-bottom: 1px solid rgba(0,0,0, 0.2);
      font-size: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 2px;
      padding-left: 2px;
      cursor: pointer;
    }
  }
    
`

export default TransportTableStyled

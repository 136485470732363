const initialState = {
    token: false,
    isPreloaderActive: false,
    transportationFilter: {
        licensePlate: '',
        licensePlateSort: '',
        status: '',
        statusSort: ''
    },
    transportFilter: {
        licensePlate: ''
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_FIELD':
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }

        default:
            return state
    }
}

export default reducer

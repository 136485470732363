const translateStatus = (status) => {
    if (status == 'returned') {
        return 'Retour'
    }

    if (status == 'new') {
        return 'Nieuw'
    }

    if (status == 'picked_up') {
        return 'Rijdend'
    }

    if (status == 'cancelled') {
        return 'Geannuleerd'
    }

    return status
}

export {translateStatus}

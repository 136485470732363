import React, {useEffect, useState} from 'react'
import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import logo from "../assets/logo.png";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createNewTransportation, getNewTransportation} from "../redux/actions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SelectSearch from 'react-select-search';
import '../styles/app.css'
var moment = require('moment-timezone');


const NewTransportationPage = () => {

    const {id} = useParams()
    const token = useSelector(state => state.token)
    const dispatch = useDispatch()
    const history = useHistory()

    const [returnLocations, setReturnLocations] = useState([])

    const [transportationInfo, setTransportationInfo] = useState(false)
    const [newTransportation, setNewTransportation] = useState({
        pickup_location_id: 0,
        return_location_id: 0,
        driver_id: 0,
        transport_reason: 0,
        transport_method: 0,
        from_date: moment(new Date()).tz("Europe/Amsterdam"),
        to_date: moment(new Date()).tz("Europe/Amsterdam").add(1, 'hours'),
        need_action: false,
    })

    useEffect(() => {
        console.log(newTransportation)
    }, [newTransportation])

    useEffect(() => {
        if (transportationInfo) {
            setNewTransportation({
                ...newTransportation,
                pickup_location_id: transportationInfo.vehicle.start_place.id
            })

            const newLocations = transportationInfo.return_locations.map((item) => {
                return {
                    name: item.name,
                    value: item.id
                }
            })

            setReturnLocations(newLocations)

        }
    }, [transportationInfo])

    useEffect(() => {
        if (id && token) {
            dispatch(
                getNewTransportation({
                    token,
                    carID: id,
                    onSuccess: (res) => {
                        setTransportationInfo(res)
                    }
                })
            )
        }
    }, [id, token])

    const onDateChange = (e) => {
        setNewTransportation({
            ...newTransportation,
            [e.target.name]: moment(e.target.value)
        })
    }

    const onSelectChange = (e) => {
        setNewTransportation({
            ...newTransportation,
            [e.target.name]: e.target.value
        })
    }

    const onSelectReturnLocationChange = (e) => {
        setNewTransportation({
            ...newTransportation,
            return_location_id: e
        })
    }

    const onCreateTransportation = () => {
        dispatch(
            createNewTransportation({
                token,
                vanId: id,
                transportation: newTransportation,
                onSuccess: (res) => {
                    history.push(`/transportation/${res.reservation_id}`)
                }
            })
        )
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#E9392C'
                }}>
                    <img onClick={ () => {history.push('/')} } src={logo}/>
                </Box>
            </Grid>
            {
                transportationInfo ?
                    <Grid item xs={12} style={{padding: '8px'}}>
                        <h3 style={{textAlign: 'center'}}>Nieuw transport</h3>
                        <p>
                            <b>Voertuig</b>: {transportationInfo.vehicle.description} ({transportationInfo.vehicle.license_plate})
                        </p>
                        <p>
                            <b>Van (Datum/tijd)</b>
                            <br/>
                            <input
                                name="from_date"
                                onChange={onDateChange}
                                style={{marginTop: '10px', fontFamily: 'Roboto', fontSize: '16px'}}
                                value={newTransportation.from_date.format('YYYY-MM-DDTHH:mm')}
                                type="datetime-local"
                            />
                        </p>
                        <p>
                            <b>Tot (Datum/tijd)</b>
                            <br/>
                            <input
                                name="to_date"
                                onChange={onDateChange}
                                style={{marginTop: '10px', fontFamily: 'Roboto', fontSize: '16px'}}
                                value={newTransportation.to_date.format('YYYY-MM-DDTHH:mm')}
                                type="datetime-local"
                            />
                        </p>

                        <p><b>Naam bestuurder</b>:</p>

                        <FormControl style={{width: '100%'}}>
                            <Select value={newTransportation.driver_id} name="driver_id" onChange={onSelectChange}>
                                <MenuItem disabled={true} value={0}>Kies bestuurder</MenuItem>
                                {
                                    transportationInfo.drivers.map((item) => {
                                        return (
                                            <MenuItem value={item.id}>{item.full_name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                        <p><b>Ophaallocatie</b>: {transportationInfo.vehicle.start_place.name}</p>

                        <p><b>Retourlocatie</b></p>

                        {/*<FormControl style={{width: '100%'}}>*/}
                        {/*    <Select*/}
                        {/*        name="return_location_id"*/}
                        {/*        onChange={onSelectChange}*/}
                        {/*        value={newTransportation.return_location_id}>*/}
                        {/*        <MenuItem disabled={true} style={{fontSize: '14px'}} value="0">Kies retourlocatie</MenuItem>*/}
                        {/*        {*/}
                        {/*            transportationInfo.return_locations.map((item) => {*/}
                        {/*                return (*/}
                        {/*                    <MenuItem style={{fontSize: '14px', whiteSpace: 'normal'}} value={item.id}>*/}
                        {/*                        {item.name}*/}
                        {/*                    </MenuItem>*/}
                        {/*                )*/}
                        {/*            })*/}
                        {/*        }*/}
                        {/*    </Select>*/}
                        {/*</FormControl>*/}

                        <FormControl style={{width: '100%'}}>
                            <SelectSearch
                                name="return_location_id"
                                value={newTransportation.return_location_id}
                                onChange={onSelectReturnLocationChange}
                                style={{
                                    width: '100%'
                                }}
                                search={true}
                                options={returnLocations} />
                        </FormControl>

                        <p><b>Reden van reservering</b>:</p>
                        <FormControl style={{width: '100%'}}>
                            <Select onChange={onSelectChange} name="transport_reason" value={newTransportation.transport_reason}>
                                <MenuItem value="0">Standaard reden van reservering</MenuItem>
                                {
                                    transportationInfo.transport_reasons.map((item) => {
                                        return (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                        <p><b>Wijze van transport</b>:</p>
                        <FormControl style={{width: '100%'}}>
                            <Select onChange={onSelectChange} name="transport_method" value={newTransportation.transport_method}>
                                <MenuItem disabled={true} value="0">Kies wijze van transport</MenuItem>
                                {
                                    transportationInfo.transport_methods.map((item) => {
                                        return (
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                            <Button onClick={onCreateTransportation}
                                    color="success"
                                    variant="contained">
                                Reservering opslaan
                            </Button>

                            <Button onClick={() => {
                                history.push('/')
                            }}> <ArrowBackIcon/> Terug</Button>
                        </div>

                    </Grid>
                    :
                    ''
            }
        </Grid>
    )
}

export default NewTransportationPage

import React from 'react'
import {Switch, Redirect, Route} from 'react-router-dom';
import DashboardPage from "./pages/DashboardPage";
import AuthPage from "./pages/AuthPage";
import TransportationsPage from "./pages/TransportationsPage";
import NewTransportationPage from "./pages/NewTransportationPage";
import TransportationPage from "./pages/TransportationPage";
import TransportsPage from "./pages/TransportsPage";

const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact component={DashboardPage} />
            <Route path="/auth" exact component={AuthPage} />
            <Route path="/transportations" exact component={TransportationsPage} />
            <Route path="/transportation/:id" exact component={TransportationPage} />

            <Route path="/transports" exact component={TransportsPage} />
            <Route path="/new-transportation/:id" exact component={NewTransportationPage} />
        </Switch>
    )
}

export default Routes

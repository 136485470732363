import styled from "styled-components";

const PreloaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.8);
  z-index: 100000;
`

export default PreloaderWrapper

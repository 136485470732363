import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react'
import {changeField, getTransportations} from "../redux/actions";
import TransportTableStyled from "../styles/TransportTableStyled";
import {useHistory} from "react-router-dom";
import useDebounce from '../hooks/useDebounce'
import {translateStatus} from "../services/translationService";

const TransportationsTable = () => {

    const token = useSelector(state => state.token)
    const [transportations, setTransportations] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const filter = useSelector(state => state.transportationFilter)

    const onChange = (e) => {
        dispatch(
            changeField({
                field: 'transportationFilter',
                value: {
                    ...filter,
                    [e.target.name]: e.target.value
                }
            })
        )
    }

    useEffect(() => {
        if (token) {

            let filterValue = ''

            if (filter.licensePlate || filter.status) {
                filterValue += `?license_plate=${filter.licensePlate}&status=${filter.status}`
            }

            dispatch(
                getTransportations({
                    token,
                    filter: filterValue,
                    onSuccess: (data) => {
                        setTransportations(data)
                    }
                })
            )
        }
    }, [token, filter])

    const onTransportationClick = ({transportationID}) => {
        history.push(`/transportation/${transportationID}`)
    }

    return (
        <TransportTableStyled>

            <div className="tableHeaderRow" style={{display: 'flex'}}>
                <div className="tableHeaderLabel">Kenteken</div>
                <div className="tableHeaderLabel">Status</div>
            </div>

            <div className="tableHeaderRow">
                <div className="tableHeaderFilter">
                    <input name="licensePlate" onChange={onChange} value={filter.licensePlate} type="text"/>
                </div>
                <div className="tableHeaderFilter">
                    <select onChange={onChange} value={filter.status} name="status" id="">
                        <option value="">All</option>
                        <option value="new">Nieuw</option>
                        <option value="picked_up">Rijdend</option>
                        <option value="returned">Retour</option>
                        {/*<option value="expired">expired</option>*/}
                        <option value="cancelled">Geannuleerd</option>
                        <option value="concept">concept</option>
                    </select>
                </div>
            </div>

            <div className="tableBody">
                {
                    transportations ?
                        transportations.map((item) => {

                            if (item[4] != 'expired') {
                                return (
                                    <div
                                        onClick={() => {
                                            onTransportationClick({transportationID: item[0]})
                                        }}
                                        key={item[0]} className="tableRow">
                                        <div>{item[2]}</div>
                                        <div>{ translateStatus(item[4])}</div>
                                    </div>
                                )
                            }


                        })
                        :
                        ''

                }
            </div>

        </TransportTableStyled>
    )
}

export default TransportationsTable

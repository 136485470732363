import React from 'react'
import {Avatar, Box, Button, Container, CssBaseline, Grid} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import logo from '../assets/logo.png'
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {changeField} from "../redux/actions";

const DashboardPage = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    const onLinkClick = ({link}) => {
        history.push(link)
    }

    return (
        <Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#E9392C'
                }}>
                    <img src={logo}/>
                </Box>
            </Grid>

            <Grid item xs={12} style={{display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                height: 'calc(100vh - 76px)'
            }}>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
                    <Button style={{backgroundColor: '#E9392C'}}
                            size="large"
                            variant="contained"
                            onClick={() => {
                                onLinkClick({link: '/transportations'})
                            }}
                    >
                        TRANSPORTOPDRACHTEN
                    </Button>
                </Grid>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                    <Button style={{backgroundColor: '#E9392C'}} size="large" variant="contained"
                            onClick={() => {
                                onLinkClick({link: '/transports'})
                            }}
                    >
                        NIEUWE TRANSPORTOPDRACHT</Button>
                </Grid>
                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                    <Button style={{backgroundColor: '#E9392C'}} size="large" variant="contained"
                            onClick={() => {
                                window.localStorage.removeItem('token')
                                dispatch(
                                    changeField({
                                        field: 'token',
                                        value: false
                                    })
                                )
                                history.push('/auth')
                            }}
                    >
                        Logout</Button>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default DashboardPage

import React from 'react'
import {Box, Grid} from "@mui/material";
import logo from "../assets/logo.png";
import TransportationsTable from "../components/TransportationsTable";
import TransportsTable from "../components/TransportsTable";
import {useHistory} from "react-router-dom";

const TransportsPage = () => {

    const history = useHistory()

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#E9392C'
                }}>
                    <img onClick={ () => {history.push('/')} } src={logo}/>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <TransportsTable />
            </Grid>
        </Grid>
    )
}

export default TransportsPage

import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getTransportations} from "../redux/actions";
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import {Box, Grid} from "@mui/material";
import logo from "../assets/logo.png";
import TransportationsTable from "../components/TransportationsTable";
import {useHistory} from "react-router-dom";

const TransportationsPage = () => {

    const history = useHistory()

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#E9392C'
                }}>
                    <img onClick={ () => {history.push('/')} } src={logo}/>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <TransportationsTable />
            </Grid>
        </Grid>
    )
}

export default TransportationsPage
